.loading_modal{
    border-radius: 20px;
    max-width: 464px;
    max-height: 177px;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 5, 13, 1);
    border: 1px solid rgba(1, 254, 72, 1);
    position: absolute!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex!important;
    z-index: 999;
  }
  
  .loading_modal_bg{
    content: "";
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background-color: rgba(9, 5, 13, 0.8);
    position:absolute;
    display: flex!important;
  
    z-index: 99;
  }
  
  .loading_modal_header{
    color: rgba(1, 254, 72, 1);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
  }
  
  .loading_modal_title{
    width: 100%;
    text-align: center;
    text-justify:auto;
    color: rgba(108, 231, 114, 1);
    margin: 20px 0;
  }
  .loading_modal_body{
    height: 100%;
    text-align: center;
    color: #fff;
  }
  
  .modal-content{
    height: 100%;
  }
  
  .tilt_animation{
    animation: tilt 0.7s infinite linear;
    position:relative;
  }
  
  @keyframes tilt {
    0% {
      transform:rotate(0deg);right: 0px;
    }
    25% {
       transform:rotate(30deg);right: -10px;
    }
    75% {
       transform:rotate(-30deg);right: 10px;
    }
    100% {
       transform:rotate(0deg);right: 0px;
    }
  }